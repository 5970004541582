//background for nav header
@each $name, $color in $theme_backgrounds {
  [data-primary="#{$name}"] {
    @if $name != "color_1" {
      --primary: #{$color};
      --primary-hover: #{darken($color, 10)};
      --primary-dark: #{darken($color, 30)};
      --rgba-primary-1: #{rgba($color, 0.1)};
      --rgba-primary-2: #{rgba($color, 0.2)};
      --rgba-primary-3: #{rgba($color, 0.3)};
      --rgba-primary-4: #{rgba($color, 0.4)};
      --rgba-primary-5: #{rgba($color, 0.5)};
      --rgba-primary-6: #{rgba($color, 0.6)};
      --rgba-primary-7: #{rgba($color, 0.7)};
      --rgba-primary-8: #{rgba($color, 0.8)};
      --rgba-primary-9: #{rgba($color, 0.9)};
    }
  }
}

@each $name, $color in $theme_backgrounds {
  [data-secondary="#{$name}"] {
    @if $name != "color_1" {
      --secondary: #{$color};
      --secondary-dark: #{darken($color, 10)};
    }
  }
}

body[data-theme-version="dark"] {
  .text-body {
    color: $dark-font-body !important;
  }
}

body[data-theme-version="dark"] {
  .text-white {
    color: $white !important;
  }
}

body[data-theme-version="dark"] {
  .bg-primary {
    background-color: $dark-primary !important;
  }
  .bg-secondary {
    background-color: $dark-primary !important;
  }
  .text-primary {
    color: $dark-font-primary !important;
    fill: $dark-font-primary !important;
    path {
      fill: $dark-font-primary;
    }
  }
}
