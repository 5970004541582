[data-theme-version="dark"] {
  .footer {
    background-color: $dark-card;

    .copyright {
      background-color: transparent;

      p {
        color: $dark-font-primary;
      }
    }
  }
  .footer-menu div span svg path {
    fill: $dark-font-primary;
  }
}
