[data-theme-version="dark"] {
  .nav-header {
    border-color: $d-border;
    background-color: $dark-sidebar;
  }
  &[data-sidebar-position="fixed"][data-layout="vertical"] {
  }

  .nav-control {
    svg {
      rect {
        fill: $dark-font-primary;
      }
    }
  }

  .brand-logo {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  .svg-title-path {
    fill: $white;
  }
}
